<template>
  <main class="p-4 w-full newPropertyContainer">
    <div class="border border-gray-300 rounded p-4 newPropertyCard">
      <h1 class="font-bold text-lg mb-5">New Property</h1>
      <form @submit.prevent="makelink">
        <div class="mt-2">
          <label for="" class="font-bold">Campaign Name</label>
          <input
            type="text"
            class="textInput my-1"
            placeholder="Campaign Name"
            required
            v-model="name"
          />
        </div>

        <div class="mt-2">
          <label for="" class="font-bold">Destination</label>
          <input
            type="url"
            class="textInput my-1"
            placeholder="Destination"
            required
            v-model="destination"
          />
        </div>
        <div class="flex-col flex mt-2">
          <label for="" class="font-bold">Domain</label>
          <select name="domain my-1" id="" v-model="domain" required>
            <option value="ronan.link">ronan.link/</option>
            <option value="SRL-Public">stomprocket.link/</option>
          </select>
        </div>

        <div class="flex-col flex mt-2">
          <label for="" class="font-bold my-1">Link Type</label>
          <select name="shortenedType" id="" v-model="linkType" required>
            <option value="hri">human readable</option>
            <option value="random">random alpha-numeric</option>
            <option value="custom">custom</option>
          </select>
        </div>

        <div v-if="linkType == 'custom'" class="flex flex-row my-2">
          <input
            type="text"
            :placeholder="initialRandomID"
            class="textInput"
            v-model="customLink"
            :required="linkType == 'custom'"
          />
        </div>
        <p class="font-bold mt-10">Final Link</p>
        <p>{{ link }}</p>
        <button class="btn--fullwidth mt-2" type="submit">Create</button>
      </form>
    </div>
  </main>
</template>
<script>
import { getAuth } from "firebase/auth";
var hri = require("human-readable-ids").hri;
let ShortUniqueId = require("short-unique-id");
const uid = new ShortUniqueId({ length: 6 });
//console.log(uid.availableUUIDs(),uid.approxMaxBeforeCollision(), uid.collisionProbability())
export default {
  name: "NewPropertyView",
  components: {},
  data() {
    return {
      hri: this.generateHRI(),
      randomID: this.generateRandomID(),
      linkType: "hri",
      domain: "SRL-Public",
      customLink: "",
      destination: "",
      name: ""
    };
  },
  async created() {
    const auth = getAuth();
    console.log("NewPropertyView created", this.user, auth.currentUser);
  },
  computed: {
      domainReadable() {
          if (this.domain == "SRL-Public") {
              return "stomprocket.link"
          } else {
              return this.domain
          }
      },
    shortenedURL() {
      if (this.linkType == "hri") {
        return this.hri;
      } else if (this.linkType == "random") {
        return this.randomID;
      } else if (this.linkType == "custom") {
        return this.customLink;
      } else {
        return "error";
      }
    },
    link() {
      return `${this.domainReadable}/${this.shortenedURL}`;
    },
    api() {
      return this.$store.getters.api;
    },
  },
  methods: {
    generateHRI() {
      return hri.random();
    },
    generateRandomID() {
      return uid();
    },
    async makelink() {
           const auth = getAuth();
      if (this.linkType == "custom" && this.customLink.length < 6) {
        console.log("not long enough");
        return;
      }
      let idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);
      let response = await fetch(this.api + "/properties", {
          method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
            },
            body: JSON.stringify({
                name: this.name,
                destination: this.destination,
                domain: this.domain,
                linkType: this.linkType,
                shortened: this.shortenedURL,
            }),
      }).then(response => response.json());
      console.log(response)
      if (response.acknowledged) {
        this.$router.push("/");
      }
    },
  },
};
</script>
