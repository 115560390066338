import { createStore } from 'vuex'

export default createStore({
  state: {
    user: false,
    token: false,
    apiLocal: 'http://localhost:3001/api/v1',
    apiProduction: 'https://stomprocket.link/api/v1',
    properties: [],
  },
  getters: {
    api: state => {
      if (window.location.hostname === 'localhost') {
        return state.apiLocal
      } else {
        return state.apiProduction
      }
      
    },
    properties: state => {
      return state.properties
    }
  },
  mutations: {
    setAuth(state, user) {
      state.user = user
    },
    setToken(state, token) {
      state.token = token
    },
    setProperties(state, properties) { 
      state.properties = properties
    },
    addProperty(state, property) {
      state.properties.push(property)
    }
  },
  actions: {
  },
  modules: {
  }
})
