<template>
  <main class="p-4 authGrid place-content-center w-full gap-4">
    <div
      class="border border-gray-300  rounded col-span-1 col-start-2 row-start-2 bg-white p-4"
    >
      <form @submit.prevent="login" class="flex flex-col h-full">
        <h1 class="text-lg font-bold">Login</h1>
        <input
          type="email"
          class="textInput mt-auto"
          placeholder="email"
          required
          v-model="email"
        />
        <input
          type="password"
          class="textInput my-1"
          placeholder="password"
          required
          v-model="pass"
        />
        <button type="submit" class="btn--fullwidth mt-auto">Login</button>
      </form>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//import firebase from 'firebase'
import { getAuth, signInWithEmailAndPassword,onAuthStateChanged } from "firebase/auth";

export default {
  name: "AuthView",
  data() {
    return {
      email: "",
      pass: "",
    };
  },
  components: {},
  created() {
       const auth = getAuth();
      console.log('AuthView created', this.user)
      if (this.user) {
            console.log("logged in")
        this.$router.push("/");
      
      }
      onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
   // const uid = user.uid;
     console.log("logged in, user:", user);
            this.$store.commit("setAuth", user);
            this.$router.push("/")
    // ...
  } else {
    // User is signed out
    // ...
  }
});
  },
  methods: {
    login() {
      console.log("logging in", this.email, this.pass);
      if (this.email.length > 0 && this.pass.length > 0) {
        console.log("logging in");
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.email, this.pass)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log("logged in, user:", user);
            this.$store.commit("setAuth", user);
            this.$router.push("/")
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("error logging in", errorCode, errorMessage);
          });
      } else {
        console.log("doesn't meet logging parameters");
      }
    },
  },
    computed: {
    user () {
      return this.$store.state.user
    }
  }
};
</script>
