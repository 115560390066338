<template>
<div class="appContainer h-screen">
<nav class="flex flex-row p-4 items-center">
  <div class="flex flex-row items-center">
    <a href="https://link.stomprocket.io/stomprocket" class="transition-all hover:opacity-75">
     <img class="h-10" src="./assets/logo.svg" alt="stomprocket logo">
    </a>
   <router-link to="/"  class="transition-all hover:opacity-75">
      <p class="font-bold text-xl">Stomp Rocket Links</p></router-link>
    </div>
      <button class="ml-auto btn--secondary">Settings</button>
  <router-link to="/new" class=" ml-2 btn--primary">New Link</router-link>
</nav>
  <router-view/>
</div>

</template>
