import { createApp } from 'vue'
import Popper from "vue3-popper";
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/main.scss"
import { initializeApp } from "firebase/app";
//import 'flowbite';
const firebaseConfig = {
  apiKey: "AIzaSyA6ATir_aPLh3P9-juc22aaw4cd-OnMS4Q",
  authDomain: "stomp-rocket-analytics.firebaseapp.com",
  projectId: "stomp-rocket-analytics",
  storageBucket: "stomp-rocket-analytics.appspot.com",
  messagingSenderId: "705626604214",
  appId: "1:705626604214:web:b026d661752038afa2dc17",
  measurementId: "G-YV75S515XW"
};

// Initialize Firebase
initializeApp(firebaseConfig);

//eslint-disable-next-line
createApp(App).use(store).use(router).component("Popper", Popper).mount('#app')
