<template>
  <main class="p-4 w-full">
    <div class="propertyRow">
      <p class="font-bold">Name</p>
      <p class="font-bold">Destination</p>
      <p class="font-bold">Shortened URL</p>
      <div></div>
      <p class="font-bold text-right">Opens</p>
      <p class="font-bold text-right">First Open</p>
      <p class="font-bold text-right">Last Open</p>
    </div>
    <button
      v-bind:key="property._id"
      v-for="property in properties"
      class="propertyRow propertyItem py-2"
    >
      <p class="text-ellipsis overflow-hidden whitespace-nowrap">
        {{ property.name }}
      </p>
       <Popper class="text-ellipsis overflow-hidden whitespace-nowrap" :content="property.destination" hover="true">
      <a class="text-ellipsis overflow-hidden whitespace-nowrap" :href="property.destination" target="_blank">
        {{ property.destination }}
      </a>
       </Popper>
      <Popper content="click to coppy" hover="true">
      <button @click="copyText(`https://${domainReadable(property.domain)}/${property.shortened}`)">
        <p
        class="text-ellipsis overflow-hidden whitespace-nowrap">
        {{ domainReadable(property.domain) }}/{{ property.shortened }}
      </p>
        </button>
      </Popper>
      <div></div>
      <p class="text-right text-ellipsis overflow-hidden whitespace-nowrap">
        {{ property.views.views.length }}
      </p>
      <p class="text-right text-ellipsis overflow-hidden whitespace-nowrap">
        Uesterday
      </p>
      <p class="text-right text-ellipsis overflow-hidden whitespace-nowrap">
        Tomorrow
      </p>
    </button>
  </main>
</template>

<script>
// @ is an alias to /src
import { getAuth } from "firebase/auth";

export default {
  name: "HomeView",
  components: {},
  async created() {
    const auth = getAuth();
    console.log("HomeView created", this.user);
    if (this.user || auth.currentUser) {
      console.log("logged in");
      //  this.$router.push("/");
      let idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);
      //console.log("token", idToken);
      this.$store.commit("setToken", idToken);
      try {
        let data = await fetch(this.api + "/properties", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
        }).then((response) => response.json());
        console.log(data);
        if (data.properties) {
          // var results = []
          this.$store.commit("setProperties", []);
          await data.properties.forEach(async (i) => {
            let views = await fetch(this.api + `/properties/${i._id}/views`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
            }).then((res) => res.json());
            console.log(views);
            i.views = views;
            this.$store.commit("addProperty", i);
            this.generateToolTips();
            //results.push(i)
            return;
          });
          

          // console.log(results, "results")
          //this.$store.commit("setProperties", results);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.$router.push("/auth");
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    api() {
      return this.$store.getters.api;
    },
    properties() {
      return this.$store.getters.properties;
    },
  },
  methods: {
    copyText(text) {
        navigator.clipboard.writeText(text);
    },
    generateToolTips() {
     
    },
    domainReadable(domain) {
      if (domain == "SRL-Public") {
        return "stomprocket.link";
      } else {
        return domain;
      }
    },
  },
};
</script>
